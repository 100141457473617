import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Video = makeShortcode("Video");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Caption>`}</inlineCode>{` component is typically used below images or videos. They will default to 4 columns wide unless placed inside a `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` component. In this case, it will assume the full width of its containing column.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <h4>{`Normal`}</h4>
    <Video src="/videos/hero-video.mp4" poster="/images/poster.png" mdxType="Video" />
    <Caption mdxType="Caption">
  This is a regular caption. It will attempt to respond to it's container
  element appropriately.
    </Caption>
    <h4>{`Full-width`}</h4>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.555555555555554%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVQY0x3MT0hTcQDA8d/ewxgG4mQqQi6k50LRjSJs0KGkltUhkrYio8XS1ALpUIcozYZJfyCIQLA6SGkho5WZEMZKGAkeNFc6PUQEZVpUU/faDrX3vv3o8OF7+wqlci1KZS5KhQ3FWY2ysUp2C6q2G7V8J6qjHtV+ElHYRGnBUTbn+3HZjrAj102d1YHXWoZ3TbFUKBUhVN96VP86VF8F4sBhyYfY34TY043Y24mltpecmpcIzyieqgiNG/ppcIa5WHSMq7btdBfs4kqeg668YqkEYQ9twx7ykH+5Dkt7O5aO81gu3EKcjSHORVHaZrE2phHNOv76JD2137nmXeWF6zoTWpBxZytvta3EtWqmNTfCNdTApqFDlD89hYg8RjwZJCc8Rkm/jn1gGXdfBv8d2HcPbt6AVx0wHIKF0yNkgj3oJ+6SPX4GM9CMEWhFBOa6CM53cnDuNmWzM5Qm4tS8/0TbJLRMwaUJk76YQe8bk+ioQeKZwfRzSD2agfsxjAfjmAMRzIeDUhjxOj3FWHqS6O8EIymdYV0nmsrwbtkkviIHSYOPPww+/DRY+pZl5etfkotZ/iysYn75hSFlPy9Ji//9A4LILAKbqMyBAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Colors",
        "title": "Colors",
        "src": "/static/c0fc9864a6ce4ef42cef74f3f451329e/3cbba/colors.png",
        "srcSet": ["/static/c0fc9864a6ce4ef42cef74f3f451329e/7fc1e/colors.png 288w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/a5df1/colors.png 576w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/3cbba/colors.png 1152w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/25c8a/colors.png 1592w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption fullWidth mdxType="Caption">
  This is a full width caption. With this prop, the caption's width will be 100%
  so it will fill its container.
    </Caption>
    <h2>{`Code`}</h2>
    <h4>{`Normal`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Caption/Caption.js src= https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Caption",
        "path": "components/Caption/Caption.js",
        "src": "",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Caption": true
      }}>{`<Caption>
  This is a regular caption. It will attempt to respond to it's container
  element appropriately.
</Caption>
`}</code></pre>
    <h4>{`Full-width`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Caption/Caption.js src= https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Caption",
        "path": "components/Caption/Caption.js",
        "src": "",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Caption": true
      }}>{`<Caption fullWidth>
  This is a full width caption. With this prop, the caption's width will be 100%
  so it will fill its container.
</Caption>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fullWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`❌`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to full width`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`⚠️`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🚫`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      